import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { HashRouter as Router } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { msalConfig } from './config/MsalConfig'
import Incident_Provider from './Context/IncidentContext/incidentProvider'
import GuideLine_Provider from './Context/GuideLineContext/guideLineProvider'
import BusinessImapctGaps_Provider from './Context/BusinessImpactGapsContext/businessImpactGapsProvider'
import Application_Provider from './Context/ApplicationContext/applicationProvider'

export const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }

  if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    sessionStorage.clear()
    localStorage.clear()
    window.location.href = '/'
  }
})

createRoot(document.getElementById('root')).render(
  <Router>
    <Incident_Provider>
      <GuideLine_Provider>
        <BusinessImapctGaps_Provider>
          <Application_Provider>
            <App pca={msalInstance} />
          </Application_Provider>
        </BusinessImapctGaps_Provider>
      </GuideLine_Provider>
    </Incident_Provider>
  </Router>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
